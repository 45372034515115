<template>
  <el-form
    ref="$form"
    :model="model"
    label-position="right"
    label-width="100px"
    size="small"
  >
  <el-form-item :rules="rules.nickName" prop="nickName" label="昵称">
    <el-input v-model="model.nickName" placeholder="请输入" clearable></el-input>
  </el-form-item>

    <el-form-item :rules="rules.wx" prop="phone" label="微信">
      <el-input v-model="model.wx" placeholder="请输入" clearable></el-input>
    </el-form-item>
    <el-form-item  prop="phone" label="手机号">
      <el-input v-model="model.phone" placeholder="请输入" clearable></el-input>
    </el-form-item>
    <el-form-item :rules="rules.email" prop="email" label="邮箱">
      <el-input v-model="model.email" placeholder="请输入" clearable></el-input>
    </el-form-item>
    <el-form-item :rules="rules.cardPic" prop="headUrl" label="头像">
      <fileUpload limit="1" :fileType="['png', 'jpg', 'jpeg']" v-model="model.headUrl" />
    </el-form-item>
    <el-form-item :rules="rules.wxPic" prop="wxPic" label="微信二维码">
      <fileUpload limit="1" :fileType="['png', 'jpg', 'jpeg']" v-model="model.wxPic" />
    </el-form-item>
    <div class="btns">
      <el-button size="small" type="primary" @click="submit">提 交</el-button>
      <el-button size="small" @click="close">取 消</el-button>
    </div>
  </el-form>
</template>
<script>
import { memberUserupdateInfo } from "@/request/api.js";

export default {
  name: "editure",
  components: {},
  data() {
    return {
      forms: ["$form"],
      model: {
        phone: "",
        email: "",
        cardPic: "",
        wxPic: "",
      },
      rules: {
        wx: [],
        email: [],
        cardPic: [],
        wxPic: [],
      },
      submit_loading: false,
    };
  },

  methods: {
    close() {
      this.$parent.$parent.editUserDia = false;
    },
    submit() {
      Promise.all(this.forms.map((form) => this.$refs[form].validate())).then(
        () => {
          memberUserupdateInfo(this.model).then((res) => {
            this.$message.success("修改成功");
            this.$emit("closeDia");
          });
        }
      );
    },
    init(data) {
      console.log("123");
      console.log(data);
      this.model = data;
    },
  },
  created() {},
};
</script>
<style scoped>
.j-el-upload-picture-card-drag >>> .el-upload--picture-card {
  border: none !important;
}

.j-el-upload-picture-card-drag >>> .el-upload-dragger {
  width: 148px !important;
  height: 148px !important;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>